"use client";
import dynamic from "next/dynamic";
import Image from "next/image";

const OwlCarousel = dynamic(() => import("react-owl-carousel"), { ssr: false });

const TrustedBy = () => {
  const options = {
    loop: true,
    center: false,
    items: 6,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 3000,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    nav: false,
    slidetransition: "linear",
    autoheight: "true",
    stageClass: "owl-stage stager",
    responsive: {
      0: {
        items: 2,
        margin: 5,
      },
      600: {
        items: 3,
        margin: 5,
      },
      768: {
        items: 3,
        margin: 5,
      },
      1000: {
        items: 6,
        margin: 5,
      },
    },
  };
  return (
    <>
      <section className="pt-70">
        <div className="container_wrap">
          <div className="row">
            <div className="col-12">
              <div className="trusted_client">
                <h2>Trusted By</h2>
              </div>
            </div>
            <div className="col-12 pt-40">
              <OwlCarousel
                {...options}
                className="owl-carousel owl-theme slider_wrap trusted_slider owl-loaded owl-drag "
              >
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={100}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider01.png`}
                    alt="client_1"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={98}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider02.png`}
                    alt="client_2"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={22}
                    width={157}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider03.png`}
                    alt="client_3"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={100}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider04.png`}
                    alt="client_4"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={100}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/mercer_icon.png`}
                    alt="client_5"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={100}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src="https://cdn.moontechnolabs.com/live/images/zeroeyes.svg"
                    alt="client_6"
                  />
                </div>
                <div className="item client-imgs">
                  <Image
                    height={35}
                    width={121}
                    loading="lazy"
                    unoptimized
                    className="slick-lazy"
                    src="https://cdn.moontechnolabs.com/live/images/magnima.svg"
                    alt="client_7"
                  />
                </div>
                {/* <div className="item client-imgs">
                  <Image
                    as="image"
                    className="slick-lazy"
                    src="{`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider01.png"
                    alt="client_7"
                    loading="lazy"
                  />
                </div> */}
                {/* <div className="item client-imgs">
                  <Image
                    as="image"
                    className="slick-lazy"
                    src="{`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/slider02.png"
                    alt="client_8"
                    loading="lazy"
                  />
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustedBy;
