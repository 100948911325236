"use client";
import dynamic from "next/dynamic";
import Image from "next/image";
import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
const OwlCarousel = dynamic(() => import("react-owl-carousel"), { ssr: false });

export default function HealthClients({ trusted }) {
  const options = {
    loop: true,
    center: false,
    items: 6,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 3000,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    nav: false,
    slidetransition: "linear",
    stageClass: "owl-stage stager",
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 6,
      },
    },
  };

  const clientData = [
    { src: "houston.svg", alt: "client_1", width: 126, height: 34 },
    { src: "zeroeyes.svg", alt: "client_2", width: 126, height: 34 },
    { src: "siemens.svg", alt: "client_3", width: 126, height: 34 },
    { src: "freelap.svg", alt: "client_4", width: 126, height: 34 },
    {
      src: "seekrz.svg",
      alt: "client_5",
      width: 107,
      height: 14,
      priority: true,
    },
    { src: "lcbo.svg", alt: "client_6", width: 126, height: 34 },
    { src: "magnima.svg", alt: "client_7", width: 126, height: 34 },
    { src: "spendbitcoins.svg", alt: "client_8", width: 126, height: 34 },
    { src: "mercer_icon.png", alt: "client_9", width: 126, height: 34 }
  ];

  return (
    <div className="esteemed-clients mt_mobile_30">
      {/* <section className="health-clients our-clients"> */}
      <section className="health-clients">
        <div className="bg-lightblue esteemed-images">
          <div className={`container ${trusted ? "" : "p-40"}`}>
            <div className="row d-md-flex display-block align-center">
              <div className="col-lg-2 col-sm-3">
                <div className="trusted-title">
                  <h2 className="font-24 text-nowrap fw-600 m-0">TRUSTED BY</h2>
                </div>
              </div>
              <div className="col-lg-10 col-sm-9">
                <div className="wrapper">
                  <OwlCarousel
                    className="owl-theme center-slider trust-client"
                    {...options}
                  >
                    {clientData.map((client, index) => (
                      <div className="item client-imgs" key={index}>
                        <Image
                          loading={client.priority ? "eager" : "lazy"}
                          unoptimized
                          as="image"
                          className="slick-lazy"
                          width={client.width}
                          height={client.height}
                          src={
                            client.src === 'mercer_icon.png'
                              ? `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/${client.src}`
                              : `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${client.src}`
                          }
                          alt={client.alt}
                          priority={client.priority || false}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
