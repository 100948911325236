"use client";
import React, { useState } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";

const InfrastructureModel = dynamic(() => import("../../../components/InfrastructureModel"), {
  ssr: false,
});

const Infrastucture = () => {
  const videoUrl = "https://www.youtube.com/embed/Etrn68T-M0k";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="infrastructure container">
        <div className="infrastructur_inner">
        <div className="services_child">
            <h2 className="center_under_line">Innovative Workspace of Moon Technolabs</h2>
          </div>
       
          <div className="play_button_mtpl" onClick={openModal}>
            <Image
              className="lazy"
              src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_IMAGE_SVG}/company_img.jpg`}
              alt="node_indu_image_6"
              width={1266}
              height={461}
              loading="lazy"
              unoptimized
            />
            <div className="play">
              <svg className="video-overlay-play-button new_1" viewBox="0 0 200 200">
                <circle cx="100" cy="100" r="80" fill="none" strokeWidth="18" stroke="#fff"></circle>
                <polygon points="70, 55 70, 145 145, 100" fill="#fff"></polygon>
              </svg>
            </div>
          </div>
        </div>

        <InfrastructureModel videoUrl={videoUrl} isOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </>
  );
};

export default Infrastucture;
