"use client";
import dynamic from "next/dynamic";
import Image from "next/image";
import React from "react";

const OwlCarousel = dynamic(() => import("react-owl-carousel"), { ssr: false });

const TestimonialClients = () => {
  const options = {
    items: 1,
    loop: true, 
    nav: true,
    dots: false,
    navText: [
      "<button class='nav-btn prev-slide '>Previous</button>",
      "<button class='nav-btn next-slide active-button'>Next</button>",
    ],
    responsive: {
      0: { items: 1, dots: false },
      768: { items: 1, margin: 20, dots: false },
      1000: { items: 1, margin: 20, dots: false },
      1200: { items: 1, dots: false },
    },
  };

  const services = [
    {
      id: 1,
      name: "Ayse D.",
      position: "Co-Founder",
      country: "France",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/france_flag.svg`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/mtpl_client1.webp`,
      feedback: "I highly recommend Moon Technolabs as the quality of service is wonderful. We have hired this company to develop the product based on some complex & technical issues. We get the best quality services as compared with others in the market. Huge Thanks to Moon Technolabs as the team is always ready to give the solution all time.",
      videoUrl: "https://www.youtube.com/embed/ew4FSuJ0-YE",
    },
    {
      id: 2,
      name: "Justin G.",
      position: "Founder & CEO",
      country: "United States",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/america_flag.svg`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/ClientJustin.webp`,
      feedback: "Moon Technolabs is a pioneer in the WebRTC based project as they have fixed complicated segments of the module by fulfilling different product lines by providing 24X7 customer support. We really recommended Moon Technolabs as they are able to develop products as per the module deadline and project timeline.",
      videoUrl: "https://www.youtube.com/embed/LDnzC5IjAOw",
    },
    {
      id: 3,
      name: "Yousef A.",
      position: "Challenger BH, Founder",
      country: "Jordan",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/jordan.webp`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/mtpl_client7.webp`,
      feedback: "Moon Technolabs provides the best mobile app development solutions and as a team, they are amazing to fulfil my requirements of sports mobile application. I recommend all the time Moon Technolabs. Thanks!",
      videoUrl: "https://www.youtube.com/embed/uwgtvJ75kbo",
    },
    {
      id: 4,
      name: "Ehis E.",
      position: "Owner",
      country: "Canada",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_URL_SERVICES}/canada.webp`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/mtpl_client8.webp`,
      feedback: "Moon Technolabs has created a portal by integrations with multiple modules including ETR functionality as per the requirement of the client. Here, the challenging part is to gather information, understand the idea and know about the scope of work in a given timeline. For that Team MTPL has discussed different modules and was clear about the requirements with a deadline and developed...",
      videoUrl: "https://www.youtube.com/embed/BdysDDJuhFA",
    },
    {
      id: 5,
      name: "Flavio S.",
      position: "Founder & Managing Director",
      country: "Germany",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/germany_flag.svg`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/mtpl_client3.webp`,
      feedback: "I am happy to recommend Moon Technolabs for their app development services. They successfully developed apps for me, and I am highly satisfied with the overall outcomes. The development team has swiftly addressed the issues with responsive and effective communication to understand the requirement quickly and actively resolve the back-and-forth problems that arose....",
      videoUrl: "https://www.youtube.com/embed/3FrbEbkAis0",
    },
    {
      id: 6,
      name: "Jay M.",
      position: "Founder & CEO",
      country: "United States",
      flag: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/america_flag.svg`,
      image: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/ClientJayM.webp`,
      feedback: "Moon Technolabs is the best company that provides advanced apps and websites development services in the USA and Europe. I am a newbie to develop my app with an external team. I am really happy to work with them as I am not that much mobile apps user. Here, the team and specially the CEO of Moon Technolabs helps me to let me know about the benefits of my app to generate revenue....",
      videoUrl: "https://www.youtube.com/embed/_RUtKvC6mHw",
    },
  ];

  return (
    <section className="pt-70 ios_ecosystem">
      <div className="container_wrap">
        <div className="row">
          <div className="col-12">
            <div className="trusted_client ios_services">
              <h2>Why Clients Trust Us?</h2>

            </div>
          </div>
          <div className="col-12 pt-40 relative">
            <OwlCarousel {...options} className="owl-carousel slider_info owl-loaded owl-drag">
              {services.map((service) => (
                <div className="item" key={service.id}>
                  <div className="client_slider_info">
                    <a
                      href="#"
                      className="videopopup video-wrapper testimonial_v1"
                      data-val={service.videoUrl}
                      data-title={service.name}
                      data-toggle="modal"
                      data-target="#sub_popup"
                    >
                      <div className="client_img">
                        <Image
                          height={400}
                          width={635}
                          loading="lazy"
                          unoptimized
                          src={service.image}
                          alt={service.name}
                        />
                        <svg
                          className="play_icon_btn"
                          width="76"
                          height="76"
                          viewBox="0 0 76 76"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_b_544_1612)">
                            <circle
                              cx="38"
                              cy="38"
                              r="38"
                              fill="white"
                              fillOpacity="0.6"
                            ></circle>
                          </g>
                          <path
                            d="M47.2486 35.2412L34.1994 27.702C33.8264 27.4813 33.4016 27.3633 32.9682 27.36C32.2869 27.36 31.6335 27.6306 31.1518 28.1124C30.6701 28.5941 30.3994 29.2475 30.3994 29.9288V46.1092C30.3995 46.5613 30.5207 47.0051 30.7504 47.3945C30.9801 47.7839 31.3099 48.1047 31.7056 48.3235C32.1012 48.5422 32.5483 48.651 33.0002 48.6385C33.4521 48.626 33.8925 48.4927 34.2754 48.2524L47.3398 39.9988C47.746 39.7446 48.0795 39.3894 48.3076 38.968C48.5358 38.5466 48.6509 38.0733 48.6417 37.5942C48.6325 37.115 48.4993 36.6465 48.2552 36.2341C48.0111 35.8217 47.6643 35.4797 47.2486 35.2412Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                    </a>
                    <div className="client_inner">
                      <div className="client_review">
                        <h5>{service.name}</h5>
                        <h6>{service.position}</h6>
                      </div>
                      <div className="client_location">
                        <Image
                          height={20}
                          width={28}
                          loading="lazy"
                          unoptimized
                          src={service.flag}
                          alt={service.country}
                        />
                        <span>{service.country}</span>
                      </div>
                      <p>{service.feedback}</p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialClients;
