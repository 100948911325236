"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

const Feature = ({
  title,
  tabData = [],
  sectionId,
  width,
  height,
  customClass = "",
}) => {
  const [currentImage, setCurrentImage] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const [hasEnteredView, setHasEnteredView] = useState(false);
  const intervalRef = useRef(null);
  const scrollRef = useRef(null);
  const hasStartedRotation = useRef(false);
  const isSectionInView = useRef(false);

  // Ensure animation resets on first render
  useEffect(() => {
    if (tabData.length && hasEnteredView) {
      setCurrentImage(tabData[0]?.image || "");
      setActiveTab(1);
    }
  }, [tabData, hasEnteredView]);

  const startImageRotation = () => {
    if (hasStartedRotation.current || !tabData.length) return;
    hasStartedRotation.current = true;

    intervalRef.current = setInterval(() => {
      setActiveTab((prev) => {
        const next = prev === tabData.length ? 1 : prev + 1;

        // Only trigger animation if it's NOT switching back to the first tab
        if (next !== 1) {
          setActiveAnimation(true);
        }

        setTimeout(() => {
          setCurrentImage(tabData[next - 1]?.image || "");
          setActiveAnimation(false);
        }, 500);

        return next;
      });
    }, 6000);
  };

  const stopImageRotation = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    hasStartedRotation.current = false;
  };

  const handleTabClick = (tabIndex) => {
    if (tabIndex === activeTab || activeAnimation) return;
    stopImageRotation();
    setActiveAnimation(true);

    setActiveTab(tabIndex);
    setCurrentImage(tabData[tabIndex - 1]?.image || "");

    setTimeout(() => {
      setActiveAnimation(false);
      startImageRotation();
    }, 0);
  };

  useEffect(() => {
    if (scrollRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !hasEnteredView) {
            setHasEnteredView(true);
            setActiveTab(1);
            setCurrentImage(tabData[0]?.image || "");
            setTimeout(() => {
              setActiveAnimation(false);
              // hasStartedRotation.current = false; // Ensure fresh rotation start
              startImageRotation();
            }, 100);
          } else if (!entry.isIntersecting) {

            // isSectionInView.current = false;
            stopImageRotation();
          }
        },
        { threshold: 0.2 }
      );

      observer.observe(scrollRef.current);
      return () => observer.disconnect();
    }
  }, [tabData]);

  return (
    <section className="portfolio_feature" ref={scrollRef} id={sectionId || ""}>
      <div className="container">
        <div className="row common_flex">
          <div className="col-lg-12">
            <div
              className="feature_title"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h2>{title}</h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`moon_dialer_tab ${customClass}`}>
              <div className="dialer_tab_inner d-flex">
                <Image
                  height={height}
                  width={width}
                  // height={600}
                  // width={325.07}
                  loading="lazy"
                  unoptimized
                  alt="feature_img"
                  src={currentImage}
                  className={`fade-in ${activeAnimation ? "animating" : ""}`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="accordion">
              {tabData.map((item, index) => {
                const tabIndex = index + 1;

                return (
                  <div
                    key={tabIndex}
                    className={`${activeTab === tabIndex ? "position-relative linear" : ""
                      }`}
                  >
                    <div
                      className={`card ${activeTab === tabIndex
                        ? "new_blog_active fade-in-content"
                        : ""
                        }`}
                    >
                      <div
                        className={`card-header ${activeTab === tabIndex ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(tabIndex)}
                      >
                        <span>{item.title}</span>
                        <span className="icon"></span>
                      </div>
                      <div
                        className={`card-body`}
                        style={
                          activeTab === tabIndex
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {item.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
