"use client";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import PhoneInput from "react-phone-input-2";

import { useLoader } from "../LoaderProvider";
import { useCountry } from "../CountryProvider";

const ValidationForm = () => {
  const { showLoader, hideLoader } = useLoader();
  const router = useRouter();
  const { data } = useCountry();
  const maxFileSize = 10 * 1024 * 1024;
  const allowedFileTypes = [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".zip"];

  var browserInfo = (function () {
    if (typeof window === "undefined") {
      return "Not in a browser environment";
    }

    var userAgent = window.navigator.userAgent;

    var getBrowserVersion = function (regexp) {
      var match = regexp.exec(userAgent);
      return match && match.length > 1 ? match[1] : "";
    };

    switch (true) {
      case /edg/i.test(userAgent):
        return {
          name: "Microsoft Edge",
          version: getBrowserVersion(/edg\/([\d.]+)/i),
        };
      case /trident/i.test(userAgent):
        return {
          name: "Microsoft Internet Explorer",
          version: getBrowserVersion(/rv:([\d.]+)/i),
        };
      case /firefox|fxios/i.test(userAgent):
        return {
          name: "Mozilla Firefox",
          version: getBrowserVersion(/(?:firefox|fxios)\/([\d.]+)/i),
        };
      case /opr\//i.test(userAgent):
        return {
          name: "Opera",
          version: getBrowserVersion(/opr\/([\d.]+)/i),
        };
      case /ucbrowser/i.test(userAgent):
        return {
          name: "UC Browser",
          version: getBrowserVersion(/ucbrowser\/([\d.]+)/i),
        };
      case /samsungbrowser/i.test(userAgent):
        return {
          name: "Samsung Browser",
          version: getBrowserVersion(/samsungbrowser\/([\d.]+)/i),
        };
      case /chrome|chromium|crios/i.test(userAgent):
        var chromeVersion = getBrowserVersion(/(?:chrome|crios)\/([\d.]+)/i);
        return {
          name: "Google Chrome",
          version: chromeVersion,
          formatted: `Google Chrome ${chromeVersion} Windows`,
        };
      case /safari/i.test(userAgent):
        return {
          name: "Apple Safari",
          version: getBrowserVersion(/version\/([\d.]+)/i),
        };
      default:
        return { name: "Other", version: "" };
    }
  })();

  const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/api/mtplUserCall";
  const cookiesData = Cookies.get();

  function handleKeyPress(e) {
    var key = e.key;
    var regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      budget: "",
      message: "",
      attachment: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required").min(10),
      message: Yup.string().required("Message is required"),
      // .min(50, "Message must be at least 50 characters"),
      attachment: Yup.array().of(
        Yup.mixed()
          .test("fileType", "Invalid file type", (value) => {
            if (!value) {
              return false;
            }
            const fileExtension = value.name.split(".").pop();
            return allowedFileTypes.includes(`.${fileExtension.toLowerCase()}`);
          })
          .test("fileSize", "File size too large", (value) => {
            if (!value) {
              return false;
            }
            return value.size <= maxFileSize;
          })
      ),
    }),
    onSubmit: async (values) => {
      delete data["timezone"];
      showLoader();
      var parts = cookiesData?._ga?.split(".");
      var timestamp = parts
        ? parts[parts?.length - 2] + "." + parts[parts?.length - 1]
        : 0;

      try {
        let object = {
          ...values,
          client_id: timestamp,
          client_referrer:
            cookiesData?.Clientreferrer === "undefined"
              ? location.href
                  .replaceAll("?", "$")
                  .replaceAll("&", "$")
                  .split("$")
                  .filter((utm) => utm.includes("utm_source"))
                  .map((utm) => utm.split("="))
                  .flat()[1]
              : cookiesData?.Clientreferrer,

          gcn: cookiesData?._gcn || "",
          gid: cookiesData?._gid || "",
          gc_lid: cookiesData?._gclid || "",
          gc_src: cookiesData?._gclsrc || "",
          client_ip: data.ip,
          client_city: data.city,
          client_state: data.region,
          client_country: data.country_name,
          client_country_code: data.country_code,
          client_browser: browserInfo.formatted,
          page_url: window.location.href,
          page_ref_url: window.location.href,
          subject: process.env.NEXT_PUBLIC_SUBJECT_POPUP_SUBJECT,
          get_page_ref: "banner-form",
        };

        const formdata = new FormData();
        for (const key in object) {
          if (key == "attachment") {
            let value = object[key];

            for (let i = 0; i < value.length; i++) {
              formdata.append("attachment[]", value[i]);
            }
          } else {
            formdata.append(key, object[key]);
          }
        }

        const response = await axios.post(apiUrl, formdata);

        if (response.ok) {
          const responseData = await response.json();
        } else {
          router.push(process.env.NEXT_PUBLIC_API_URL + "/thank-you");
          validation.resetForm();
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
    },
  });

  useEffect(() => {
    const getCookie = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const myCookieValue = getCookie("yourCookieName");
  }, []);
  const nameInputRef = useRef(null);

  const handleResize = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth <= 768) {
      nameInputRef.current.removeAttribute("autoFocus");
    } else {
      nameInputRef.current.setAttribute("autoFocus", true);
      nameInputRef.current.focus();
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {/* <link
        href="https://cdn.jsdelivr.net/npm/react-phone-input-2@2.15.1/lib/style.min.css"
        rel="stylesheet"
      ></link> */}
      <form
        id="cnt_main_us_page_form"
        encType="multipart/form-data"
        className="cnt_main_footer_form validation_new row bannerform"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="col-md-6 pr-10">
          <div className="form-group">
            <label className="pure-material-textfield-outlined label-banner">
              <input
                type="text"
                name="name"
                placeholder=" "
                maxLength="25"
                id="nameInput1"
                onKeyPress={(e) => handleKeyPress(e)}
                ref={nameInputRef}
                className={
                  validation.touched.name &&
                  validation.errors.hasOwnProperty("name") &&
                  alert
                    ? "validation_new"
                    : ""
                }
                onChange={(e) => {
                  validation.handleChange(e);
                }}
                value={validation.values.name}
              />
              <span className="custom_span hides lh-52">
                <span className="text_area_top label-quote">Name*</span>
              </span>
            </label>
          </div>
        </div>

        <div className="col-md-6 pl-10">
          <div className="form-group">
            <label className="pure-material-textfield-outlined label-banner">
              <input
                type="email"
                name="email"
                placeholder=" "
                id="emailInput1"
                className={
                  validation.touched.email &&
                  validation.errors.hasOwnProperty("email") &&
                  alert
                    ? "validation_new"
                    : ""
                }
                onChange={(e) => {
                  validation.handleChange(e);
                }}
                value={validation.values.email}
              />
              <span className="custom_span hides lh-52">
                <span className="text_area_top label-quote">Email*</span>
              </span>
            </label>
          </div>
        </div>

        <div className="col-md-12">
          <div className="phone-select">
            <div className="phone-wrap col-md-6 p-0">
              <div className="form-group w-100 position-relative">
                <div className="form-group">
                  <PhoneInput
                    name="phone"
                    style={
                      validation.touched.phone &&
                      validation.errors.hasOwnProperty("phone") &&
                      alert
                        ? {
                            marginBottom: "0",
                            borderColor: "red",
                            borderTopColor: "red",
                          }
                        : { marginBottom: "0" }
                    }
                    country={
                      data.country_code ? data.country_code.toLowerCase() : "in"
                    }
                    enableSearch={true}
                    value={validation.values.phone}
                    onChange={(value) => {
                      validation.setFieldValue("phone", value);
                    }}
                    placeholder="Enter phone number"
                    inputProps={{ maxLength: 18 }}
                    disableSearchIcon={false}
                    searchPlaceholder="Search..."
                  />
                </div>
              </div>
            </div>
            <div className="select-wrap">
              <div className="form-group quote-select">
                <div className="form-group">
                  <label className="select_wrap">
                    <select
                      className="budget_select_div"
                      id="budget_select_div_footer7"
                      aria-label="budget_select_div_footer"
                      name="budget"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.budget}
                      style={{
                        color: validation.values.budget ? "black" : "#828282",
                      }}
                    >
                      <option
                        className="options"
                        value=""
                        style={{ color: "#828282", paddingTop: "5px" }}
                      >
                        Select Approx Budget
                      </option>
                      <option
                        className="option_budget"
                        value="Less than $10,000"
                      >
                        Less than $10,000
                      </option>
                      <option
                        className="option_budget"
                        value="$10,000 - $25,000"
                      >
                        $10,000 - $25,000
                      </option>
                      <option
                        className="option_budget"
                        value="$25,000 - $50,000"
                      >
                        $25,000 - $50,000
                      </option>
                      <option
                        className="option_budget"
                        value="$50,000 and above"
                      >
                        $50,000 and above
                      </option>
                      <option className="option_budget" value="Not Sure">
                        Not Sure
                      </option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-12 ">
          <div className="form-group">
            <div className="col-md-12 padding_right browsefile_div  browsefile_solution">
              <div
                className="attach_file_div"
                style={
                  validation.touched.attachment &&
                    validation.errors.hasOwnProperty("attachment") &&
                    alert
                    ? {
                      marginBottom: "0",
                      borderColor: "red",
                      borderTopColor: "red",
                    }
                    : { marginBottom: "0" }
                }
              >
                <label className="browse_file">
                  <label htmlFor="files1">
                    <span style={{ color: fileName.length > 0 ? "black" : "#a8a8a8" }}>
                      {fileName.length > 0 ? fileName[fileName.length - 1] : "Upload File"}
                    </span>
                  </label>
                  <input
                    name="attachment"
                    id="files1"
                    type="file"
                    multiple
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={(e) => {
                      if (e.target.files[0] && e.target.files) {
                        setfileName([...Array.from(e.target.files).map((item) => item.name)]);
                        validation.setFieldValue("attachment", [...files, ...Array.from(e.target.files)]);
                      }
                    }}
                  />
                  <Image
                    className="lazy"
                    loading="lazy"
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${process.env.NEXT_PUBLIC_IMAGE_SVG}/attachment.svg`}
                    alt="Moon Technolabs"
                    height={24}
                    width={24}
                  />
                </label>
              </div>
              <div className="text_left">
                <small style={{ color: "#a8a8a8", fontSize: "12px" }}>
                  Acceptable Format:.Doc,.PDF,.XLS (Max size: 10MB)
                </small>
              </div>
            </div>
          </div>

        </div> */}

        <div className="col-md-12">
          <div className="form-group ">
            <label className="pure-material-textfield-outlined label-banner">
              <textarea
                rows="3"
                name="message"
                id="message1"
                placeholder="Project Description"
                className={
                  validation.touched.message &&
                  validation.errors.hasOwnProperty("message") &&
                  alert
                    ? "validation_new form-control text-area "
                    : "form-control text-area"
                }
                onChange={(e) => {
                  validation.handleChange(e);
                }}
                value={validation.values.message}
              ></textarea>

              <span className="custom_span hides">
                <span className="text_area_top label-quote">
                  Project Description*
                </span>
              </span>
            </label>
          </div>
        </div>

        <input
          type="hidden"
          name="page_url"
          value=""
          className=""
          id="banner_frm_page_url"
        />

        <input
          type="hidden"
          name="client_id"
          value=""
          className=""
          id="banner_frm_ga_client_id"
        />
        <input
          type="hidden"
          name="client_referrer"
          value=""
          className=""
          id="banner_frm_ga_referrer_id"
        />
        <input
          type="hidden"
          name="gc_lid"
          value=""
          className=""
          id="banner_frm_gc_lid_id"
        />
        <input
          type="hidden"
          name="gc_src"
          value=""
          className=""
          id="banner_frm_gc_src"
        />
        <input
          type="hidden"
          name="gcn"
          value=""
          className=""
          id="banner_frm_gcn"
        />
        <input
          type="hidden"
          name="gid"
          value=""
          className=""
          id="banner_frm_gid"
        />
        <input
          type="hidden"
          name="client_ip"
          value=""
          className=""
          id="banner_frm_ga_client_ip"
        />
        <input
          type="hidden"
          name="client_city"
          value=""
          className=""
          id="banner_frm_ga_client_city"
        />
        <input
          type="hidden"
          name="client_state"
          value=""
          className=""
          id="banner_frm_ga_client_state"
        />
        <input
          type="hidden"
          name="client_country"
          value=""
          className=""
          id="banner_frm_ga_client_country"
        />

        <input
          type="hidden"
          name="client_browser"
          value=""
          className=""
          id="banner_frm_client_browser"
        />
        <input
          type="hidden"
          name="client_country_code"
          value=""
          className=""
          id="inq_client_country_code"
        ></input>
        <input type="hidden" name="secret" value="" className="" id="secret1" />
        <input type="hidden" name="iv" value="" className="" id="iv1" />
        <input
          type="hidden"
          name="website"
          value=""
          className=""
          id="website"
        />
        <input
          type="hidden"
          name="subject"
          value=""
          className=""
          id="banner_frm_client_subject"
        />
        <div className="col-md-12">
          <button
            type="submit"
            id="popup_btn_id31"
            className="btn w-100 submit-btn primary-btn patientcare-btn "
            aria-label="banner-form"
          >
            Get a Free Quote
          </button>
        </div>
        <div
          className="alert alert-success"
          id="suc_cnt_btn"
          style={{ display: "none" }}
        >
          <strong>Success!</strong> Your Message Sent successfully.
        </div>
        <div
          className="alert alert-danger"
          id="dan_cnt_btn"
          style={{ display: "none" }}
        ></div>
      </form>
    </>
  );
};

export default ValidationForm;
