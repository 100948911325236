"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

const TabList = ({
  caseStudies,
  activeIndex,
  handleTabClick,
  handleTabDoubleClick,
}) => (
  <ul className="moon_tab tab-links">
    {caseStudies.map((caseStudy, index) => (
      <li
        key={index}
        className={index === activeIndex ? "active" : ""}
        onClick={() => handleTabClick(index)}
        onDoubleClick={() => handleTabDoubleClick(index)}
      >
        {caseStudy.tabTitle || caseStudy.title}
      </li>
    ))}
  </ul>
);

const CaseStudyContent = ({ caseStudy, active }) => (
  <div
    className={`tab-content ${caseStudy.tabClass} ${active ? "active" : ""
      } pl-40`}
  >
    <div className="moon_invoce">
      <h2>{caseStudy.title}</h2>
      <p>{caseStudy.description}</p>
      <div className="kay_feature_wrap">
        <div className="key_feature">
          <h4>Category</h4>
          <ul>
            <li>{caseStudy.category}</li>
          </ul>
        </div>
        <div className="teck_stack_wrap key_feature">
          <h4>Tech Stack</h4>
          <ul>
            {caseStudy.techStack.map((tech, i) => (
              <li key={i}>{tech}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="case_button pt-40">
        <button
          className="ios_btn"
          onClick={() => (window.location.href = caseStudy.url)}
        >
          View Full Case Study
        </button>
      </div>
    </div>
  </div>
);

const CaseStudyImage = ({ caseStudy, active }) => (
  <div className={`case_studie tab-content height_660 ${caseStudy.tabClass} ${active ? "active" : ""}`}>
    <Image
      height={596}
      width={655}
      loading="lazy"
      unoptimized
      src={caseStudy.imgSrc}
      alt={caseStudy.alt}
    />
  </div>
);

const Casestudy = ({ caseStudies, title, description }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % caseStudies.length);
    }, 5000);

    return () => clearInterval(intervalRef.current);
  }, [caseStudies.length]);

  const handleTabClick = (index) => {
    setActiveIndex(index);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % caseStudies.length);
    }, 5000);
  };

  const handleTabDoubleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="pt-70">
      <div className="container_wrap">
        <div className="row">
          <div className="col-12">
            <div className="trusted_client ios_services">
              <h2>{title}</h2>
              <p className="ios_inner_text max_width_1200">{description}</p>
            </div>
          </div>
          <div className="ios_height">
            <div className="case_studie_bg mt-50">
              <div className="col-md-5">
                {caseStudies.map((caseStudy, index) => (
                  <CaseStudyImage
                    key={index}
                    caseStudy={caseStudy}
                    active={index === activeIndex}
                  />
                ))}
              </div>

              <div className="col-md-7">
                <div className="case_wrap">
                  <TabList
                    caseStudies={caseStudies}
                    activeIndex={activeIndex}
                    handleTabClick={handleTabClick}
                    handleTabDoubleClick={handleTabDoubleClick}
                  />
                </div>

                {caseStudies.map((caseStudy, index) => (
                  <CaseStudyContent
                    key={index}
                    caseStudy={caseStudy}
                    active={index === activeIndex}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Casestudy;
